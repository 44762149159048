'use strict';

/*******************************************************************************************/
class UxrGpEdit extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			'itemDI': null,
			'listDI': null,
			'value': null,
		};
		
		this.onJQueryMount = this.onJQueryMount.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onEditorSave = this.onEditorSave.bind(this);
		this.onEditorClose = this.onEditorClose.bind(this);
		this.onEditorDelete = this.onEditorDelete.bind(this);
		
		this.value = _.cloneDeep(this.props.value);
	}
	
	/*---------------------------------------------------------------------*/
	render() {
		
		let humanId = '';
		
		let contentEditor = null;
		if (this.state.itemDI && this.state.listDI) {
			let model = this.state.listDI.node.data.model.structure();
			let Frame = model.frame.type;
			let initialFrame = {
				idInHierarchy : IdGenerator.produce('uxr_'),
				frame : model.frame,
				content: model.content,
				value: this.value,
				onChange: this.onChange,
			};
			
			humanId = this.state.listDI.node.capabilities.humanId(
				this.state.itemDI, 
				this.state.listDI.node.data.model.readout(this.state.itemDI)
			);
			
			contentEditor = (
				<div {...this.props.htmlProps}>
					<Frame {...initialFrame} key={initialFrame.idInHierarchy} />
				</div>
			);
		} //contentEditor
		
		return (
			<div className="uiPanel ui_modelEditor glass" ref={this.onJQueryMount}>
				<div className="uiHeadline ">
					<button className="flowRight uiActButton" onClick={this.onEditorDelete}>Löschen</button>
					<button className="flowRight uiActButton" onClick={this.onEditorSave}>Speichern</button>
					<button className="flowRight uiActButton" onClick={this.onEditorClose}>Zumachen</button>
					<div className="uiTitle">{humanId}</div>
					<div className="flowClear"></div>
				</div>
				<div className="uiContent MVT_listMaster MVT_list">
					{contentEditor}
				</div>
			</div>
		);
	}
	
	/*---------------------------------------------------------------------*/
	onJQueryMount(node) {
		if (node === null) {
			return;
		}
		this.jQ = jQuery(node);
		this.props.sharedContext.jQ = this.jQ;
		this.props.sharedContext.onJQueryMount();
	}
	
	/*---------------------------------------------------------------------*/
	componentDidMount() {
		this.updateState();
		E.updateController.registerListener(this.key, this.updateListener.bind(this));
	}
	
	/*---------------------------------------------------------------------*/
	componentWillUnmount() {
		E.updateController.removeListeners(this.key);
	}
	
	/*---------------------------------------------------------------------*/
	updateState() {
		let o = this;
		
		Promise.all([
			E.localCache.dataImage.get(o.props.sharedContext.itemNodeId),
			E.localCache.dataImage.get(o.props.sharedContext.listNodeId)
		])
		.then(function(dataImages) {
			
			let itemDI = dataImages[0];
			let listDI = dataImages[1];
			if (o.state.value === null) {
				o.value = listDI.node.data.model.readout(itemDI);
			}
			
			o.setState({
				'itemDI': itemDI,
				'listDI': listDI,
				'value': o.value,
			});
			o.props.sharedContext.itemDI = itemDI;
			o.props.sharedContext.listDI = listDI;
			o.props.sharedContext.value = o.value;
		});
		
		
	}
	
	/*---------------------------------------------------------------------*/
	updateListener(messages) {
		let relevantNodes = {};
		[this.props.sharedContext.listNodeId, this.props.sharedContext.itemNodeId].forEach(function(value) {
			relevantNodes[value] = value;
		});
		
		messages = messages.filter(function(message) {
			return (message.nodeId in relevantNodes);
		});
		
		if (messages.length) {
			this.updateState();
		}
	}
	
	/*---------------------------------------------------------------------*
	componentDidUpdate() {
		let ctx = this.props.peerCount;
		let idx = this.props.peerPosition;
		
		let containerHeight = this.jQ.parent().height();
		let contentHeight = containerHeight- ((ctx-1)* 10);
		let height = contentHeight/ctx;
		let top = height*idx+ 10+ (idx-1)*10;
		
		this.jQ.css({
			'top': top+ 'px',
			'height': height+ 'px'
		});
	}
	
	/*---------------------------------------------------------------------*/
	onEditorSave(event) {
		this.props.sharedContext.onEditorSave();
	}
	
	/*---------------------------------------------------------------------*/
	onEditorClose(event) {
		this.props.sharedContext.onEditorClose();
	}
	
	/*---------------------------------------------------------------------*/
	onEditorDelete(event) {
		this.props.sharedContext.onEditorDelete();
	}
	
	/*---------------------------------------------------------------------*/
	onChange(component, msg) {
		if (('value' in msg)) {
			let valuePath = component.props.idInHierarchy.split('.');
			valuePath.shift()
			valuePath = valuePath.join('.');
			
			_.set(this.value, valuePath, msg.value);
			//small update, don't mess with state
			/*
			this.setState((prevState, props) => {
				_.set(prevState, valuePath, msg.value);
				return prevState;
			});
			*/
		}
		
		if (('add' in msg)) {
			let vvaluePath = msg.add.split('.');
			vvaluePath.shift();
			vvaluePath = vvaluePath.join('.');
			
			let values = _.get(this.value, vvaluePath) || [];
			//let newValues = _.cloneDeep(values[values.length-1]);
			values.push(undefined); //newValues);
			
			_.set(this.value, vvaluePath, values);
			
			//structural update, do mess with state
			this.setState({'value':this.value});
		}
		
		if (('remove' in msg)) {
			let waluePath = component.props.idInHierarchy.split('.');
			waluePath.shift()
			waluePath = waluePath.join('.');
			
			let values = _.get(this.value, waluePath);
			_.pullAt(values, msg.remove);
			
			//structural update, do mess with state
			this.setState({'value':this.value});
		}
	}
	
	/*---------------------------------------------------------------------*/
} //class
